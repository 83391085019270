import { render, staticRenderFns } from "./shiftLogs.vue?vue&type=template&id=b977cba8&scoped=true"
import script from "./shiftLogs.vue?vue&type=script&lang=js"
export * from "./shiftLogs.vue?vue&type=script&lang=js"
import style0 from "./shiftLogs.vue?vue&type=style&index=0&id=b977cba8&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b977cba8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/1416652-cci-50406239-448444/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b977cba8')) {
      api.createRecord('b977cba8', component.options)
    } else {
      api.reload('b977cba8', component.options)
    }
    module.hot.accept("./shiftLogs.vue?vue&type=template&id=b977cba8&scoped=true", function () {
      api.rerender('b977cba8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projectTravel/scheduling/shiftLogs.vue"
export default component.exports