<template>
  <!-- 9.0调度的班次日志 -->
  <div class="shiftLogs-page" ref="shiftLogsPage">
    <div class="return" @click="returnList">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <div class="logs-content" ref="logsContent">
      <div ref="form" class="form-area">
        <el-form
          :inline="true"
          :model="form"
          label-position="right"
          label-width="80px"
          class="demo-form-inline"
        >
          <el-form-item label="操作时间：" prop="operateTime">
            <el-time-picker
              v-model="form.operateTime"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="请选择操作时间"
            ></el-time-picker>
          </el-form-item>
          <el-form-item label="操作类型：" prop="oprateType">
            <el-select
              v-model="form.oprateType"
              clearable
              placeholder="请选择操作类型"
            >
              <el-option
                v-for="role in typeList"
                :key="role.value"
                :label="role.label"
                :value="role.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onSearch()"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <Table
        :table-data="tableData"
        :title-name="titleName"
        :operation="true"
        :col-class-type="true"
        :table-height="tableHeight"
        operation-width="240"
      >
      </Table>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      form: {
        operateTime: "",
        oprateType: "",
      },
      typeList: [],
      tableHeight: 0,
      tableData: [],
      titleName: [
        {
          title: "操作时间",
          props: "",
        },
        {
          title: "操作人",
          props: "",
        },
        {
          title: "操作账号",
          props: "",
        },
        {
          title: "操作类型",
          props: "",
        },
        {
          title: "操作详情",
          props: "",
        },
      ],
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.shiftLogsPage.clientHeight;
      const formHeight = this.$refs.form.clientHeight;      
      this.tableHeight =
        wholeHeight - 16 - formHeight  - 16 - 32;
    },
    onSearch () {

    },
    // 返回列表
    returnList () {
      this.$router.push("/projectTravel/dispatchManagement");
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
}
</script>
<style lang="scss" scoped>
.shiftLogs-page {
  height: 100%;
  width: 100%;
  overflow: scroll;
  .logs-content{
    background: #fff;
    height: calc(100% - 48px);
    margin-top: 16px;
    .form-area{
      background: #fff;
    }
  }
  .return {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  i {
    cursor: pointer;
  }
}
</style>